<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Card>
        <template #title>
          <div class="p-px-3 p-pt-3">Employee Emergency Kill Switch</div>
        </template>
        <template #subtitle>
          <div class="p-px-3">
            This will only be used when you need to remove an employee before IT has a chance to it and you're worried
            that something bad might happen.
          </div>
        </template>
        <template #content>
          <div class="p-col-12 p-px-3">
            <Message v-if="isFormSubmitted" severity="success" :sticky="true" :closable="false"
              >Successfully submitted</Message
            >
          </div>
          <div class="p-formgrid p-grid p-fluid p-px-3">
            <div class="p-grid p-md-6 p-mb-2">
              <p class="p-col-12">Platforms</p>
              <div class="p-col p-field-checkbox">
                <Checkbox
                  id="gsuite"
                  data-test="field-disable-paste-input"
                  v-model="formData.gsuite"
                  :binary="true"
                  :disabled="isFormSubmitted"
                />
                <label for="disable-paste">Gsuite</label>
              </div>
              <div class="p-col p-field-checkbox">
                <Checkbox
                  id="auth0"
                  data-test="field-disable-paste-input"
                  v-model="formData.auth0"
                  :binary="true"
                  :disabled="isFormSubmitted"
                />
                <label for="disable-paste">Auth0</label>
              </div>
              <div v-if="false" class="p-col p-field-checkbox">
                <Checkbox
                  id="bamboohr"
                  data-test="field-disable-paste-input"
                  v-model="formData.bamboohr"
                  :binary="true"
                  :disabled="isFormSubmitted"
                />
                <label for="disable-paste">BambooHR</label>
              </div>
              <div class="p-col p-field-checkbox">
                <Checkbox
                  id="fin"
                  data-test="field-disable-paste-input"
                  v-model="formData.fin"
                  :binary="true"
                  :disabled="isFormSubmitted"
                />
                <label for="disable-paste">FIN</label>
              </div>
              <div class="p-col p-field-checkbox">
                <Checkbox
                  id="timedoctor"
                  data-test="field-disable-paste-input"
                  v-model="formData.timedoctor"
                  :binary="true"
                  :disabled="isFormSubmitted"
                />
                <label for="disable-paste">Timedoctor</label>
              </div>
              <div v-if="false" class="p-col p-field-checkbox">
                <Checkbox
                  id="intercom"
                  data-test="field-disable-paste-input"
                  v-model="formData.intercom"
                  :binary="true"
                  :disabled="isFormSubmitted"
                />
                <label for="disable-paste">Intercom</label>
              </div>
            </div>
            <LabeledField
              class="p-col-12"
              label="Employee"
              :autoLayout="false"
              :show-help="v.employee.$error"
              :errors="v.employee.$errors"
            >
              <AutoComplete
                placeholder="Search by Email, Name, or Employee ID"
                field="name"
                v-model="v.employee.$model"
                :delay="500"
                :disabled="isFormSubmitted"
                :suggestions="employeeOptions"
                @complete="searchEmployees"
                forceSelection
              />
            </LabeledField>
          </div>
        </template>
        <template #footer>
          <div class="p-px-3 p-pb-3">
            <Button
              :label="!isFormSubmitted ? 'KILL IT' : 'KILL ANOTHER'"
              :disabled="!hasSelectedPlatform"
              :loading="loading"
              @click="handleSubmit($event)"
            ></Button>
          </div>
        </template>
      </Card>
    </div>
    <div class="p-col-12">
      <Card>
        <template #title>
          <div class="p-px-3 p-pt-3">FAQ</div>
        </template>
        <template #content>
          <div class="p-p-3">
            <div class="p-mb-5">
              <h5 class="p-mb-0">What does kill switch do?</h5>
              <ul>
                <li>Employee accounts will be deleted from the selected platforms.</li>
                <li>For historical purposes, records will be kept in the database.</li>
              </ul>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { computed, reactive, ref, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { required } from '@vuelidate/validators'
import { useConfirm } from 'primevue/useconfirm'

import useIntercom from '../hooks/useIntercom'
import useUsers from '../hooks/useUsers'
import { OFFBOARDING_INITIAL_STATE } from '../definitions'

const formData = reactive({ ...OFFBOARDING_INITIAL_STATE })
const employeeOptions = ref([])
const isFormSubmitted = ref(false)

const router = useRouter()
const store = useStore()
const confirm = useConfirm()
const { fetchFieldOptions } = useIntercom()
const { loading, offboardEmployee } = useUsers()

const hasSelectedPlatform = computed(() => {
  return Object.keys(formData)
    .filter((key) => key !== 'employee')
    .some((key) => !!formData[key])
})

const searchEmployees = async (event) => {
  const resp = await fetchFieldOptions('teammate', event.query)
  employeeOptions.value = resp.data
}

const handleSubmit = async (e) => {
  if (isFormSubmitted.value) {
    v.value.$reset()
    Object.assign(formData, OFFBOARDING_INITIAL_STATE)
    isFormSubmitted.value = false
    return
  }
  v.value.$touch()
  if (v.value.$error) return

  confirm.require({
    target: e.currentTarget,
    message: 'This is a destructive operation. Are you sure?',
    acceptClass: 'p-button-danger',
    accept: async () => {
      await offboardEmployee(formData.employee.id, formData)
      isFormSubmitted.value = true
    }
  })
}

// Validation
const rules = computed(() => {
  const schema = {
    employee: { required }
  }
  return schema
})
const v = useVuelidate(rules, formData)

onBeforeMount(() => {
  const userRoles = store.state.user?.roles ?? []
  if (!['escalation_manager', 'superuser'].some((r) => userRoles.indexOf(r) >= 0)) {
    router.replace({ name: 'access-denied' })
  }
})
</script>

<style scoped></style>
