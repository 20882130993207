import useHttp from './useHttp'

export default () => {
  const http = useHttp()

  const fetchFieldOptions = async (field, query) =>
    await http.exec({ url: `/intercom-auth0/${field}-search/`, params: { q: query } })

  const submitHelpEngageEntry = async (data) =>
    await http.exec({ method: 'POST', url: 'intercom-auth0/create-help-engage/', data })

  const submitHelpBKEntry = async (data) =>
    await http.exec({ method: 'POST', url: 'intercom-auth0/create-help-bk/', data })

  return { ...http, fetchFieldOptions, submitHelpBKEntry, submitHelpEngageEntry }
}
